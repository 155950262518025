import { inject, Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ApplicationHelper } from "ti-frontend-shared";
import { UserContextTO } from "../generated/transportObjects";
import { Observable } from "rxjs";
import { ApplicationStateService } from "../../core/services/application-state.service";

@Injectable({
  providedIn: "root"
})
export class LoggedIn implements CanActivate {
  private router: Router = inject(Router);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  constructor() {}

  canActivate(): boolean | Observable<boolean> {
    const loggedIn: UserContextTO = this.applicationStateService.getCurrentUser();
    if (ApplicationHelper.isObjectDefined(loggedIn)) {
      return true;
    } else {
      this.router.navigateByUrl("apps/login");
    }
  }
}
