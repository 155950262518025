import { NotificationService } from "./core/services/notification.service";
import { Component, computed, Inject, inject, OnDestroy, OnInit, signal, Signal, ViewChild, WritableSignal } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, RouterModule, RouterOutlet } from "@angular/router";
import { SidenavService } from "./core/services/sidenav.service";
import { ApplicationToolbarComponent, SpinnerService } from "ti-frontend-shared";
import { LangChangeEvent, TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApplicationStateService } from "./core/services/application-state.service";
import { PreferredLanguage, UserContextTO } from "./shared/generated/transportObjects";
import { UserService } from "./core/services/user.service";
import { PreferredLanguageEnum } from "./shared/enums/PreferredLanguage.enum";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { SidenavComponent } from "./core/components/sidenav/sidenav.component";
import { SpinnerComponent } from "./shared/components/spinner/spinner.component";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { LangTransformPipe } from "./shared/pipes/LangTransformPipe.pipe";
import { toSignal } from "@angular/core/rxjs-interop";
import { UserContextSignalStore } from "./core/signal-stores/user-context-signal-store";
import { patchState } from "@ngrx/signals";

@Component({
  selector: "ti-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    SidenavComponent,
    RouterOutlet,
    SpinnerComponent,
    TranslateModule,
    ApplicationToolbarComponent,
    NgClass,
    NgFor,
    NgIf,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatSidenavModule,
    MatButtonModule,
    RouterModule,
    LangTransformPipe
  ]
})
export class AppComponent implements OnDestroy, OnInit {
  loading: WritableSignal<boolean> = signal<boolean>(false);
  defaultLang: WritableSignal<string> = signal<string>("");
  sideNavOpened: boolean = false;
  private sideNavSubscription: Subscription;
  private spinnerSubscription: Subscription;

  private router: Router = inject(Router);
  private ns: NotificationService = inject(NotificationService);
  private navService: SidenavService = inject(SidenavService);
  private spinnerService: SpinnerService = inject(SpinnerService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private userService: UserService = inject(UserService);
  translate: TranslateService = inject(TranslateService);
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  userContextStore = inject(UserContextSignalStore);
  fullUserName: Signal<string> = computed((): string => {
    const firstName: string = this.userContextStore.userInformation.firstName();
    const lastName: string = this.userContextStore.userInformation.lastName();
    return firstName + " " + lastName;
  });
  userInfoChanged: Signal<any> = toSignal(this.ns.getUserInfoOberservable());
  enabledFreelancer: Signal<boolean> = computed(() => this.userContextStore.company.enabledFreelancer());
  constructor(@Inject("APP_VERSION") public appVersion: string) {
    this.translate.addLangs([PreferredLanguageEnum.DE_DE, PreferredLanguageEnum.EN_US]);
    this.translate.setDefaultLang(PreferredLanguageEnum.EN_US);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.defaultLang.set(event.lang);
    });
    if (this.loggedIn) {
      patchState(this.userContextStore, this.applicationStateService.getCurrentUser());
      this.translate.use(this.userContextStore.preferredLanguage());
    } else {
      this.translate.use(PreferredLanguageEnum.EN_US);
    }
  }

  ngOnInit(): void {
    this.sideNavSubscription = this.navService.sideNavTrigger.subscribe({ next: (value: boolean) => (this.sideNavOpened = value) });
    this.spinnerSubscription = this.spinnerService.observable().subscribe({ next: (value: boolean) => this.loading.set(value) });
  }

  ngOnDestroy(): void {
    this.sideNavSubscription.unsubscribe();
    this.spinnerSubscription.unsubscribe();
  }

  get loggedIn(): boolean {
    return this.applicationStateService.getCurrentUser() != undefined;
  }

  logout(): void {
    this.spinnerService.spin(true);
    setTimeout(() => {
      this.spinnerService.spin(false);
      this.applicationStateService.clearForLogout();
      this.translate.use(PreferredLanguageEnum.EN_US);
      this.router.navigateByUrl("/apps/login");
    }, 500);
  }
  navHome(): void {
    this.router.navigateByUrl("/");
  }

  getCompany(): void {
    this.router.navigateByUrl("/company/show/" + this.userContextStore.company.companyId());
  }

  switchLang(language): void {
    this.translate.use(language);
    this.updateLanguage(language);
  }

  updateLanguage(lang: string): void {
    let username: string = this.userContextStore.userInformation.uuid();
    this.userService.updatePreferredLanguage(username, lang).subscribe((res: PreferredLanguage): void => {
      let userContext: UserContextTO = this.applicationStateService.getCurrentUser();
      userContext.preferredLanguage = res;
      this.applicationStateService.setCurrentUser(userContext);
    });
  }

  openMenu(): void {
    this.menuTrigger.openMenu();
  }
}
