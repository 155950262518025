import { LoginComponent } from "./core/components/login/login.component";
import { StartComponent } from "./core/components/start/start.component";
import { Route } from "@angular/router";
import { LoggedIn } from "./shared/guards/logged-in";
import { ProductsOverviewComponent } from "./core/components/products/products-overview/products-overview.component";

export const APP_ROUTES: Route[] = [
  { path: "", redirectTo: "apps", pathMatch: "full" },
  { path: "apps", component: StartComponent, canActivate: [LoggedIn] },
  { path: "apps/login", component: LoginComponent },
  { path: "apps/products", component: ProductsOverviewComponent, outlet: "primary", canActivate: [LoggedIn] },
  {
    path: "company",
    outlet: "primary",
    canActivate: [LoggedIn],
    loadChildren: () => import("./company/company-routing").then((m: typeof import("./company/company-routing")) => m.routes)
  },
  {
    path: "customers",
    loadChildren: () => import("./customer/customer-routing").then((m: typeof import("./customer/customer-routing")) => m.routes)
  },
  {
    path: "invoices",
    loadChildren: () => import("./invoice/invoice-routing").then((m: typeof import("./invoice/invoice-routing")) => m.routes)
  },
  {
    path: "statistics",
    loadChildren: () => import("./statistics/statistics-routing").then((m: typeof import("./statistics/statistics-routing")) => m.routes)
  },
  {
    path: "freelancers",
    loadChildren: () => import("./freelancer/freelancer-routing").then((m: typeof import("./freelancer/freelancer-routing")) => m.routes)
  }
];
