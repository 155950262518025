import { Inject, inject, Injectable, signal, WritableSignal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CompanyTO } from "../../shared/generated/transportObjects";
import { ApiEndpoints } from "../../shared/utils/api-endpoints";
import { ApplicationStateService } from "./application-state.service";

@Injectable()
export class CompanyService {
  private http: HttpClient = inject(HttpClient);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  enabledFreelancer: WritableSignal<boolean> = signal(false);

  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    if (this.applicationStateService.getCurrentUser() && this.applicationStateService.getCurrentUser().company) {
      this.enabledFreelancer.set(this.applicationStateService.getCurrentUser().company.enabledFreelancer);
    }
  }
  updateCompany(company: CompanyTO, companyId: number): Observable<CompanyTO> {
    const url: string = this.serverUrl + ApiEndpoints.updateCompany(companyId);
    return this.http.put<CompanyTO>(url, company);
  }

  isEnabledFreelancer(enabled: boolean): void {
    this.enabledFreelancer.set(enabled);
  }
}
