import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { SidenavService } from "../../services/sidenav.service";
import { Event, Router, RouterEvent } from "@angular/router";
import { Subscription } from "rxjs";
import { SideNavElement } from "../../../shared/model/side-nav-element";
import { ApplicationHelper } from "ti-frontend-shared";
import { ApplicationStateService } from "../../services/application-state.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgFor } from "@angular/common";

@Component({
  selector: "ti-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  standalone: true,
  imports: [NgFor, TranslateModule]
})
export class SidenavComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  visibleNavElements: SideNavElement[] = [];
  private navService: SidenavService = inject(SidenavService);
  private router: Router = inject(Router);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);

  private customerNavElements: SideNavElement[] = [
    { title: "common.search", icon: "person_pin", target: "/customers/search" },
    { title: "common.create", icon: "person_add", target: "/customers/create" },
    { title: "common.showAll", icon: "people_alt", target: "/customers/all" }
  ];

  private freelancerNavElements: SideNavElement[] = [
    { title: "common.search", icon: "person_pin", target: "/freelancers/search" },
    { title: "common.create", icon: "person_add", target: "/freelancers/create" },
    { title: "common.showAll", icon: "people_alt", target: "/freelancers/all" }
  ];

  private invoiceElements: SideNavElement[] = [
    { title: "common.create", icon: "note_add", target: "/invoices/new", callback: (): void => this.applicationStateService.clearSteps() },
    { title: "common.all", icon: "reorder", target: "/invoices/all" },
    { title: "common.search", icon: "search", target: "/invoices/search-invoice" },
    { title: "common.credits", icon: "trending_up", target: "/invoices/credits" },
    { title: "invoice.profitReporting.profit", icon: "euro_symbol", target: "/invoices/report-profit" },
    { title: "invoice.common.positions", icon: "receipt", target: "/invoices/list-sales" }
  ];

  private statsNavElements: SideNavElement[] = [
    { title: "core.components.sideNav.overview", icon: "view_module", target: "/statistics/overview" },
    { title: "core.components.sideNav.yearSales", icon: "bar_chart", target: "/statistics/sales/year" },
    { title: "core.components.sideNav.salesTrend", icon: "trending_up", target: "/statistics/sales/trend" }
  ];
  constructor() {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe({
      next: (event: Event) => {
        if (event instanceof RouterEvent) {
          const url: string = (event as RouterEvent).url;
          if (url.startsWith("/customers")) {
            this.visibleNavElements = this.customerNavElements;
            if (!url.startsWith("/customers/welcome")) {
              this.navService.open();
            } else {
              this.navService.close();
            }
          } else if (url.startsWith("/freelancers")) {
            this.visibleNavElements = this.freelancerNavElements;
            if (!url.startsWith("/freelancers/welcome")) {
              this.navService.open();
            } else {
              this.navService.close();
            }
          } else if (url.startsWith("/invoices")) {
            this.visibleNavElements = this.invoiceElements;
            if (!url.startsWith("/invoices/welcome")) {
              this.navService.open();
            } else {
              this.navService.close();
            }
          } else if (url.startsWith("/statistics")) {
            this.visibleNavElements = this.statsNavElements;
            if (!url.startsWith("/statistics/welcome")) {
              this.navService.open();
            } else {
              this.navService.close();
            }
          } else if (url.startsWith("/apps/products")) {
            this.navService.close();
          } else if (url.startsWith("/company")) {
            this.navService.close();
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  navigate(sideNavElement: SideNavElement): void {
    if (ApplicationHelper.isObjectDefined(sideNavElement.callback)) {
      sideNavElement.callback();
    }
    this.router.navigateByUrl(sideNavElement.target);
  }
}
