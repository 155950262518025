<div class="dialog-wrapper">
  <div class="header">{{ "shared.modal.loginModal.loginRequired" | translate }}</div>
  <div class="content">
    <form [formGroup]="loginForm" (keyup.enter)="login()">
      <p>{{ "shared.modal.loginModal.enterUsernameAndPasswordOrGetKickedOut" | translate }}</p>
      <div class="form-fields">
        <mat-form-field>
          <mat-label>{{ "sharedFrontEnd.username" | translate }}</mat-label>
          <input matInput placeholder="{{ 'sharedFrontEnd.username' | translate }}" formControlName="un" #fn="matInput" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "sharedFrontEnd.password" | translate }}</mat-label>
          <input type="password" matInput placeholder="{{ 'sharedFrontEnd.password' | translate }}" formControlName="pw" />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div *ngIf="error">
    <til-message
      type="warn"
      [headline]="'Oops...'"
      [icon]="'security'"
      [content]="'sharedFrontEnd.checkUsernamePasswordAndAccessRights'"></til-message>
  </div>
  <mat-divider></mat-divider>
  <div class="actions">
    <button (click)="cancel()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button (click)="login()" mat-raised-button color="primary">{{ "sharedFrontEnd.signIn" | translate }}</button>
  </div>
</div>
