import { Router, RouterLink } from "@angular/router";
import { Component, computed, inject, Signal } from "@angular/core";
import { AuthenticationService } from "ti-frontend-shared";
import { TranslateModule } from "@ngx-translate/core";
import { UserContextSignalStore } from "../../signal-stores/user-context-signal-store";
import { NgIf } from "@angular/common";

@Component({
  selector: "ti-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"],
  standalone: true,
  imports: [RouterLink, TranslateModule, NgIf]
})
export class StartComponent {
  private as: AuthenticationService = inject(AuthenticationService);
  private router: Router = inject(Router);
  userContextStore = inject(UserContextSignalStore);
  enabledFreelancer: Signal<boolean> = computed(() => this.userContextStore.company.enabledFreelancer());
  constructor() {}
}
