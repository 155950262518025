import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "langTransform",
  standalone: true
})
export class LangTransformPipe implements PipeTransform {
  transform(value: string): string {
    const splitBy: string = "_";
    const splitText: string[] = value.split(splitBy);
    return `${splitText[0]}`;
  }
}
