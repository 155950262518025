import { Observable, throwError as observableThrowError } from "rxjs";
import { inject, Injectable } from "@angular/core";
import { LoginModalComponent } from "../../shared/modals/login-modal/login-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ErrorResponse } from "../utils/error-response";
import { JwtErrorEnum } from "../utils/JwtError.enum";

@Injectable({ providedIn: "root" })
export class ErrorResponseService {
  private dialog: MatDialog = inject(MatDialog);
  constructor() {}

  handleErrorResponse(error: any): Observable<never> {
    const json: string[] = error.error.split(/(?<=\})/);
    const errorResponse: ErrorResponse[] = json.map((err: string) => JSON.parse(err));
    const errorMessages: string[] = errorResponse.map((e: ErrorResponse) => e.message);
    if (error.status === 401) {
      //this check need to be replaced with error code check in the future.
      if (errorMessages.includes(JwtErrorEnum.JWT_EXPIRED)) {
        this.dialog.open(LoginModalComponent, { disableClose: true });
      }
    }
    return observableThrowError(error || "backend server error");
  }
}
