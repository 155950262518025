import { inject, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "tiDate",
  standalone: true
})
export class TiDatePipe implements PipeTransform {
  private datePipe: DatePipe = inject(DatePipe);
  constructor() {}

  transform(value): string {
    return this.datePipe.transform(value, "dd.MM.yyyy");
  }

  transformToYearMonth(value): string {
    return this.datePipe.transform(value, "MM/yyyy");
  }
}
