import { inject, Injectable } from "@angular/core";
import { AuthenticationService, StorageService } from "ti-frontend-shared";
import { UserContextTO } from "../../shared/generated/transportObjects";
import { UserContextSignalStore } from "../signal-stores/user-context-signal-store";
import { patchState } from "@ngrx/signals";

@Injectable({
  providedIn: "root"
})
export class ApplicationStateService {
  private stepPrefix: String = "step";
  private storageService: StorageService = inject(StorageService);
  private authService: AuthenticationService = inject(AuthenticationService);
  userContextStore = inject(UserContextSignalStore);
  constructor() {}

  handleFileInput(step: number, files: File[]): void {
    this.deleteKeysStartingWith(this.stepPrefix + step.toString() + `_file`);
    files.forEach((file: File, i: number): void => {
      const reader: FileReader = new FileReader();
      reader.onload = (): void => {
        const base64String: string = reader.result as string;
        const fileObject: { filename: string; content: string; contentType: string } = {
          filename: file.name,
          content: base64String,
          contentType: file.type
        };
        this.storageService.setItem("session", {
          key: this.stepPrefix + step.toString() + `_file_${i}`,
          value: JSON.stringify(fileObject)
        });
      };
      reader.readAsDataURL(file);
    });
  }

  deleteKeysStartingWith(startingWord: string): void {
    const keysToDelete: string[] = [];
    for (let i: number = 0; i < sessionStorage.length; i++) {
      const key: string = sessionStorage.key(i);
      if (key.startsWith(startingWord)) {
        keysToDelete.push(key);
      }
    }
    keysToDelete.forEach((key: string) => {
      sessionStorage.removeItem(key);
    });
  }

  setInvoiceStep(step: number, obj: any): void {
    this.storageService.setItem("session", { key: this.stepPrefix + step.toString(), value: JSON.stringify(obj) });
  }

  setInvoiceCopy(): void {
    this.storageService.setItem("session", { key: "invoiceCopy", value: "true" });
  }
  getInvoiceCopy(): any {
    return this.storageService.getItem("session", "invoiceCopy");
  }

  setInvoiceNumber(invoiceNumber: string): void {
    this.storageService.setItem("session", { key: "invoiceNumber", value: invoiceNumber });
  }

  getInvoiceNumber(): any {
    return this.storageService.getItem("session", "invoiceNumber");
  }

  getInvoiceStep(step: number): any {
    const obj: any = this.storageService.getItem("session", this.stepPrefix + step.toString());
    if (obj) {
      return JSON.parse(obj);
    } else {
      return null;
    }
  }

  getInvoiceStepFiles(step: number): File[] {
    const files: File[] = [];
    for (let i: number = 0; ; i++) {
      const obj: any = this.storageService.getItem("session", `${this.stepPrefix}${step}_file_${i}`);
      if (obj) {
        const file: File = this.base64ToFile(obj);
        files.push(file);
      } else {
        break;
      }
    }
    return files;
  }

  base64ToFile(storedFile: string): File {
    if (storedFile) {
      const fileObject: any = JSON.parse(storedFile);
      const base64String: string = fileObject.content;
      const filename: string = fileObject.filename;
      const contentType: string = fileObject.contentType; // Default content type

      const byteCharacters: string = atob(base64String.split(",")[1]);
      const byteNumbers: any[] = new Array(byteCharacters.length);
      for (let i: number = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray: Uint8Array = new Uint8Array(byteNumbers);
      return new File([byteArray], filename, { type: contentType });
    }
  }

  setCurrentUser(currentUser: UserContextTO): void {
    this.storageService.setItem("local", { key: "cms-user", value: JSON.stringify(currentUser) });
    patchState(this.userContextStore, currentUser);
  }

  getCurrentUser(): UserContextTO | undefined {
    const user: string = this.storageService.getItem("local", "cms-user");
    if (user) {
      return JSON.parse(user);
    } else {
      return undefined;
    }
  }

  clearSteps(): void {
    this.storageService.clearStorage("session");
  }

  clearForLogout(): void {
    this.storageService.clearStorage("session");
    this.storageService.clearStorage("local");
    this.authService.logout();
  }
}
