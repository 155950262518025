import { enableProdMode, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { APP_CONFIG, AppConfig } from "./app.config";
import { AppComponent } from "./app/app.component";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { APP_ROUTES } from "./app/app-routing";
import { tokenInterceptor } from "./app/core/interceptors/token-interceptor";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { CreditsService } from "./app/core/services/credits.service";
import { NotificationService } from "./app/core/services/notification.service";
import { Subject } from "rxjs";
import { UserService } from "./app/core/services/user.service";
import { CustomerService } from "./app/core/services/customer.service";
import { CompanyService } from "./app/core/services/company.service";
import { TiDatePipe } from "./app/shared/pipes/ti-date-pipe";
import { EuroPipe } from "./app/shared/pipes/euro-pipe";

const packageJson: any = require("../package.json");
registerLocaleData(localeDe, "de_DE", localeDeExtra);

fetch("/assets/context/context.json")
  .then((response: Response) => response.json())
  .then((config: AppConfig) => {
    if (config.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, {
      providers: [
        provideRouter(APP_ROUTES),
        importProvidersFrom(BrowserModule),
        importProvidersFrom(BrowserAnimationsModule),
        importProvidersFrom(HttpClientModule),
        tokenInterceptor,
        { provide: APP_CONFIG, useValue: config },
        {
          provide: "ADM_SERVER_URL",
          useValue: config.adminServerUrl
        },
        { provide: "SERVER_URL", useValue: config.serverUrl },
        { provide: "APP_VERSION", useValue: packageJson.version },
        importProvidersFrom(
          TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoader,
              deps: [HttpClient]
            }
          })
        ),
        { provide: MAT_DATE_LOCALE, useValue: "de_DE" },
        { provide: LOCALE_ID, useValue: "de_DE" },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DateFormats },
        TranslateService,
        DatePipe,
        CurrencyPipe,
        CreditsService,
        NotificationService,
        Subject,
        UserService,
        CustomerService,
        CompanyService,
        TiDatePipe,
        EuroPipe
      ]
    }).catch((err: any) => console.error(err));
  });

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const DateFormats: any = {
  parse: {
    dateInput: ["YYYY-MM-DD", "DD.MM.YYYY"]
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "YYYY MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM"
  }
};
