import { ApiEndpoints } from "../../shared/utils/api-endpoints";
import { inject, Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { HeaderFactory } from "../utils/header-factory";
import {
  AddressTO,
  BillingAddressTO,
  ContactPersonTO,
  CustomerHeadersTO,
  CustomerMetaDataTO,
  CustomerTO
} from "../../shared/generated/transportObjects";

@Injectable()
export class CustomerService {
  private http: HttpClient = inject(HttpClient);
  private hf: HeaderFactory = inject(HeaderFactory);

  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }

  createCustomer(customer: CustomerMetaDataTO): Observable<CustomerTO> {
    const url: string = this.serverUrl + ApiEndpoints.createCustomer;
    return this.http.post<CustomerTO>(url, customer);
  }

  updateCustomer(metaDataTO: CustomerMetaDataTO, customerNumber: string): Observable<CustomerTO> {
    const url: string = this.serverUrl + ApiEndpoints.updateCustomer(customerNumber);
    return this.http.put<CustomerTO>(url, metaDataTO);
  }

  getAllCustomers(): Observable<CustomerTO[]> {
    const url: string = this.serverUrl + ApiEndpoints.getAllCustomers;
    return this.http.get<CustomerTO[]>(url);
  }

  loadCustomerHeaders(): Observable<CustomerHeadersTO[]> {
    const url: string = this.serverUrl + ApiEndpoints.loadCustomerHeaders;
    return this.http.get<CustomerHeadersTO[]>(url);
  }

  searchCustomers(searchTerm: string): Observable<CustomerTO[]> {
    const url: string = this.serverUrl + ApiEndpoints.findByString;
    return this.http.get<CustomerTO[]>(url, { params: this.hf.create().add("searchTerm", searchTerm).build() });
  }

  deleteCustomer(customerNumber: string): Observable<void> {
    const url: string = this.serverUrl + ApiEndpoints.deleteCustomer(customerNumber);
    return this.http.delete<void>(url);
  }

  findCustomer(customerNumber: string): Observable<CustomerTO> {
    const url: string = this.serverUrl + ApiEndpoints.findCustomer + "/" + customerNumber;
    return this.http.get<CustomerTO>(url);
  }
  count(): Observable<number> {
    const url: string = this.serverUrl + ApiEndpoints.count;
    return this.http.get<number>(url);
  }

  saveAddress(address: AddressTO, customerNumber: string): Observable<AddressTO> {
    const url: string = this.serverUrl + ApiEndpoints.saveAddress(customerNumber);
    return this.http.post<AddressTO>(url, address);
  }

  saveBillingAddress(address: BillingAddressTO, customerNumber: string): Observable<BillingAddressTO> {
    const url: string = this.serverUrl + ApiEndpoints.saveBillingAddress(customerNumber);
    return this.http.post<BillingAddressTO>(url, address);
  }
  saveContactPeople(contactPeople: ContactPersonTO[], customerNumber: string): Observable<ContactPersonTO[]> {
    const url: string = this.serverUrl + ApiEndpoints.saveContactPeople(customerNumber);
    return this.http.put<ContactPersonTO[]>(url, contactPeople, { params: this.hf.create().add("customerNumber", customerNumber).build() });
  }

  getCustomerHeaders(): Observable<CustomerHeadersTO[]> {
    return this.loadCustomerHeaders().pipe(
      map((res: CustomerHeadersTO[]): CustomerHeadersTO[] => {
        return res;
      })
    );
  }
}
