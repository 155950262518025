import { inject, Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { ApplicationHelper } from "ti-frontend-shared";
import { ApplicationStateService } from "./application-state.service";
import { ErrorResponseService } from "./error-response.service";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  errorResponseService: ErrorResponseService = inject(ErrorResponseService);
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.applicationStateService.getCurrentUser()?.token;
    if (ApplicationHelper.isObjectDefined(token)) {
      request = request.clone({
        setHeaders: {
          "X-TI-Auth": "Bearer " + token
        }
      });
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error) {
            this.errorResponseService.handleErrorResponse(error);
            return throwError(() => new Error(error.message));
          }
          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
