import { NotificationService } from "./../../../core/services/notification.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Component, inject, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthenticationService, MessageComponent } from "ti-frontend-shared";
import { LoginForm } from "../../form-model/login-form";
import { UserContextTO } from "../../generated/transportObjects";
import { UserService } from "../../../core/services/user.service";
import { ApplicationStateService } from "../../../core/services/application-state.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { PreferredLanguageEnum } from "../../enums/PreferredLanguage.enum";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { NgIf } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { UserContextSignalStore } from "../../../core/signal-stores/user-context-signal-store";

@Component({
  selector: "ti-login-modal",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatDividerModule,
    MatButtonModule,
    TranslateModule,
    MessageComponent
  ]
})
export class LoginModalComponent implements OnInit {
  error: boolean = false;
  loginForm: FormGroup<LoginForm>;
  dialogRef: MatDialogRef<LoginModalComponent> = inject(MatDialogRef<LoginModalComponent>);
  private fb: FormBuilder = inject(FormBuilder);
  private router: Router = inject(Router);
  private as: AuthenticationService = inject(AuthenticationService);
  private ns: NotificationService = inject(NotificationService);
  private userService: UserService = inject(UserService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  translate: TranslateService = inject(TranslateService);
  userContextStore = inject(UserContextSignalStore);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject("ADM_SERVER_URL") private adminServerUrl: string
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.loginForm = this.fb.group<LoginForm>({
      un: this.fb.control("", Validators.required),
      pw: this.fb.control("", Validators.required)
    });
  }

  login(): void {
    this.as.authenticateUser(this.loginForm.value.un, this.loginForm.value.pw, this.adminServerUrl).subscribe({
      next: (): void => {
        this.userService.loginCmsUser(this.as.getUserToken()).subscribe({
          next: (currentUser: UserContextTO) => {
            this.applicationStateService.setCurrentUser(currentUser);
            this.translate.use(this.userContextStore.preferredLanguage());
          }
        });
        this.redirect(true);
      },
      error: (): void => {
        this.error = true;
        this.redirect(false);
      }
    });
  }

  private redirect(success: boolean): void {
    if (success) {
      if (this.data) {
        this.router.navigateByUrl(this.data);
      }
      this.dialogRef.close();
      this.ns.userInfoChanged();
    }
  }

  cancel(): void {
    this.dialogRef.close();
    this.applicationStateService.clearForLogout();
    this.translate.use(PreferredLanguageEnum.EN_US);
    this.router.navigateByUrl("/apps/login");
  }
}
