import { inject, Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: "euro",
  standalone: true
})
export class EuroPipe implements PipeTransform {
  private cp: CurrencyPipe = inject(CurrencyPipe);
  constructor() {}

  transform(value): string {
    return this.cp.transform(value, "EUR", "symbol", "1.2-2");
  }
}
